import { publicTheme } from '@/assets/styles/Theme';
import Footer from '@/ui-components/Footer';
import Navigator from '@/ui-components/Navigator';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { Outlet } from 'react-router-dom';

const navLinks = [
  { label: 'Homepage', path: '/homepage' },
  { label: 'Upcoming Publications', path: '/upcoming-publications' },
  { label: 'Submissions', path: '/submissions' },
  { label: 'Author Profiles', path: '/author-profiles' },
  { label: 'Company History', path: '/company-history' },
];

const drawerWidth = 300;
export const PublicLayout = () => {
  return (
    <ThemeProvider theme={publicTheme}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <Navigator
            navLinks={navLinks}
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: 'block', xs: 'none' } }}
          />
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Box
            component="main"
            sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}
          >
            <Outlet />
          </Box>
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
};
