import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { PublicLayout } from '@/layouts/PublicLayout';
import { AuthorProfiles } from '@/pages/public/AuthorProfiles';
import { Logout } from '@/pages/public/Logout';
import { PublicHomepage } from '@/pages/public/PublicHomepage';

import { ArchiveLayout } from '@/layouts/ArchiveLayout';
import { IdeaGetter3000 } from '@/pages/archive/IdeaGetter3000';
import { NeuroLinkHub } from '@/pages/archive/NeuroLinkHub';
import { NotFound } from '@/pages/public/NotFound';

function App() {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      {/* Public Routes */}
      <Route element={<PublicLayout />}>
        <Route path="/" element={<PublicHomepage />} />
        <Route path="/homepage" element={<PublicHomepage />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/author-profiles" element={<AuthorProfiles />} />
      </Route>
      {/* Protected Routes */}
      <Route element={<ArchiveLayout />}>
        <Route path="/ideagetter3000" element={<IdeaGetter3000 />} />
        <Route path="/archive" element={<NeuroLinkHub />} />
        {/* <Route path="/status" element={<StatusDashboard />} /> */}
      </Route>
    </Routes>
  );
}

export default App;
