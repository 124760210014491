// IdeaGetter3000.jsx
import useAxiosInstance from '@/services/useAxiosInstance';
import CustomAlert from '@/ui-components/CustomAlert';
import { OutlinedInputWithButton } from '@/ui-components/OutlinedInputWithButton';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState } from 'react';

export function IdeaGetter3000() {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const axiosInstance = useAxiosInstance();

  const callAzureFunction = async (idea) => {
    setIsLoading(true);
    setError(null);
    console.log('callAzureFunction triggered with idea:', idea);
    try {
      const ideaData = { idea };
      const ENDPOINT = '/api/Proxy-Gateway';
      const response = await axiosInstance.post(ENDPOINT, ideaData);

      if (response.data) {
        console.log(
          'Response from Azure Function: ',
          response.data.responseMessage
        );
        setMessage(response.data.responseMessage);
      } else {
        setError(
          'There was an issue processing your request. Please try again later.'
        );
      }
    } catch (error) {
      console.error('Failed to call the function! Error: ', error.message);
      setError('Failed to call the function!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
      }}
    >
      <OutlinedInputWithButton
        onSubmit={callAzureFunction}
        defaultText="Submit your idea"
      />
      {isLoading && <CircularProgress />}
      <CustomAlert message={message} severity="success" />
      <CustomAlert message={error} severity="error" />
    </Box>
  );
}
