// requestHandler.js
import { instance } from '@/config/axiosConfig'; // Import axios instance

export function setupRequestInterceptors() {
  instance.interceptors.request.use(
    (request) => {
      console.log(`Starting Request: ${JSON.stringify(request, null, 2)}`);
      return request;
    },
    (error) => {
      // Handle request error here
      return Promise.reject(error);
    }
  );
}
