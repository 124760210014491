import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

/**
 * Defines the icon mapping for navigation links.
 * @type {{[key: string]: JSX.Element}}
 */
const linkIcons = {
  Homepage: <HomeIcon />,
  'Upcoming Publications': <PeopleIcon />, // Example icon
  // ... other link icons
};

/**
 * Navigator component that renders a Drawer with navigation links.
 *
 * @param {{ navLinks: Array<{ label: string, path: string }> }} props - The component props.
 * @param {Array<{ label: string, path: string }>} props.navLinks - The navigation links to display.
 * @returns {JSX.Element} The Navigator component.
 */
export default function Navigator({ navLinks }) {
  const theme = useTheme(); // Hook to access the theme

  /**
   * Style properties for navigation items.
   * @type {object}
   */
  const item = {
    py: '2px',
    px: 3,
    color: theme.palette.text.primary,
    '&:hover, &:focus': {
      bgcolor: theme.palette.action.hover,
    },
  };

  /**
   * Style properties for category items in the navigation.
   * @type {object}
   */
  const itemCategory = {
    boxShadow: `0 -1px 0 ${theme.palette.divider} inset`,
    py: 1.5,
    px: 3,
    color: theme.palette.text.primary,
  };

  return (
    <Drawer variant="permanent">
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22 }}>
          Bird Culture Publishing
        </ListItem>
        {navLinks.map(({ label, path }) => (
          <Box key={label} sx={{ bgcolor: theme.palette.background.paper }}>
            <ListItem disablePadding>
              <ListItemButton component={RouterLink} to={path} sx={item}>
                <ListItemIcon>{linkIcons[label]}</ListItemIcon>
                <ListItemText>{label}</ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
