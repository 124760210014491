// Footer.jsx
import { Box, Link, Paper, Typography, styled } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const companyDetails = {
  name: 'Bird Culture Publishing',
  address: '7482 Featherway St.',
  city: 'Tarsa, TH 49582',
  tel: '(715) 382-9403',
  email: 'info@birdculturellc.com',
};

const StyledBox = styled(Box)({
  flex: 1,
  py: 2,
  px: 2,
  bgcolor: 'background.default',
});

const StyledPaper = styled(Paper)({
  maxWidth: 936,
  margin: 'auto',
  overflow: 'hidden',
  p: 3,
});

/**
 * Footer component displays company contact information and a newsletter sign-up link.
 *
 * @returns {React.ReactElement} The rendered Footer component.
 */
const Footer = () => {
  return (
    <StyledBox>
      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Contact Us:
        </Typography>
        <Typography variant="body1">
          <Box component="span" fontWeight="fontWeightMedium">
            {companyDetails.name}
          </Box>
          <br />
          {companyDetails.address}
          <br />
          {companyDetails.city}
          <br />
          Tel: {companyDetails.tel}
          <br />
          Email:{' '}
          <Link href={`mailto:${companyDetails.email}`} color="inherit">
            {companyDetails.email}
          </Link>
        </Typography>
        <Link component={RouterLink} to="/archive" color="inherit">
          Sign up for our Newsletter
        </Link>
      </StyledPaper>
    </StyledBox>
  );
};

export default Footer;
