// useAxiosInstance.js
import { instance } from "@/config/axiosConfig";
import { setupRequestInterceptors } from "@/services/requestHandler";
import { setupResponseInterceptors } from "@/services/responseHandler";
import useTokenManager from "@/services/tokenManager";

function useAxiosInstance() {
  useTokenManager();
  setupInterceptors();
  return instance;
}

export default useAxiosInstance;

// Singleton Axios instance
let isInterceptorsSetUp = false;

function setupInterceptors() {
  if (isInterceptorsSetUp) {
    return;
  }

  setupRequestInterceptors();
  setupResponseInterceptors();
  isInterceptorsSetUp = true;
}
