// src/pages/archive/NeuroLinkHub.jsx

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const venturesphereLinks = [
  {
    to: '/ideagetter3000',
    title: 'Idea Getter 3000',
    description: 'A place to store awesome ideas for future use.',
  },
  {
    to: '/sumthinelse',
    title: 'Sumthin Else',
    description: 'Placeholder for sumthinelse.',
  },
  {
    to: '/status',
    title: 'Status Dashboard',
    description: 'A dashboard to show the status of Github Actions.',
  },
];

const dataQuestLinks = [
  {
    to: 'https://birdculturellc.com/main_mkdocs_site',
    title: 'Techno Codex',
    description: 'Documentation for the various tools in The Archive.',
  },
  {
    to: '/insightnexus',
    title: 'Insight Nexus',
    description: 'A collection of research on various subjects.',
  },
];

export function NeuroLinkHub() {
  const allLinks = [...venturesphereLinks, ...dataQuestLinks];
  const theme = useTheme(); // Use the theme

  return (
    <Box
      sx={{
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 1,
        backgroundColor: theme.palette.background.default,
        padding: '2rem',
        border: `0.125rem solid ${theme.palette.secondary.main}`,
        boxShadow: `0 0 0.625rem ${theme.palette.secondary.main}`,
      }}
    >
      {allLinks.map((link, index) => (
        <React.Fragment key={link.to}>
          <Link to={link.to} style={{ textDecoration: 'none' }}>
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.text.primary,
                ':hover': { color: theme.palette.secondary.light },
              }}
            >
              {link.title}
            </Typography>
          </Link>
          {index < allLinks.length - 1 && (
            <Typography
              variant="body2"
              sx={{ mx: 1, color: theme.palette.text.secondary }}
            >
              |
            </Typography>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}
