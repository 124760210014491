// src/views/public/PublicHomepage.js
import { List, ListItem, Paper, Typography } from '@mui/material';

export function PublicHomepage() {
  return (
    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', p: 3 }}>
      <div>
        <Typography variant="h5" gutterBottom>
          Latest News:
        </Typography>
        <List>
          <ListItem>
            Our newest release, "Wings of Destiny", has topped the bestseller
            charts in its first week!
          </ListItem>
          <ListItem>
            Bird Culture Publishing will be attending the Tarsa Book Fair next
            month. Visit us at Stall 3B.
          </ListItem>
          <ListItem>
            New workshop for aspiring authors at our headquarters next month.
            Sign up now!
          </ListItem>
          <ListItem>
            Exciting collaboration with renowned author Olivia White for an
            upcoming fantasy series.
          </ListItem>
          <ListItem>
            Opening a new branch in downtown Tarsa to serve our readers better.
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          Upcoming Events:
        </Typography>
        <List>
          <ListItem>
            <strong>July 25th:</strong> Book signing with Laura Green for
            "Flight of Imagination".
          </ListItem>
          <ListItem>
            <strong>August 10th:</strong> Children's literature workshop with
            Rebecca Grey.
          </ListItem>
          <ListItem>
            <strong>September 3rd:</strong> Annual meeting with our community of
            readers and authors.
          </ListItem>
          <ListItem>
            <strong>October 15th:</strong> Release event for "Tales of Tarsa:
            Collector's Edition".
          </ListItem>
          <ListItem>
            <strong>November 5th:</strong> Olivia Whites's book launch event for
            "Secrets of the Night".
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          Awards:
        </Typography>
        <List>
          <ListItem>Best Publisher Award, 1995</ListItem>
          <ListItem>Literary Excellence Award, 2002</ListItem>
          <ListItem>Community Engagement Award, 2019</ListItem>
          <ListItem>
            Reader's Choice Award for "Deep Dive: Exploring Oceans", 2021
          </ListItem>
          <ListItem>Outstanding Fantasy Fiction Publisher, 2023</ListItem>
        </List>
      </div>
      <div>
        <Typography variant="h5" gutterBottom>
          Featured Books:
        </Typography>
        <List>
          <ListItem>"Flight of Imagination" by Laura Green</ListItem>
          <ListItem>"Tales of Tarsa" by Henry Masters</ListItem>
          <ListItem>"Deep Dive: Exploring Oceans" by Samantha Waters</ListItem>
          <ListItem>
            "The Bird's View: Aerial Photography" by Alex Thompson
          </ListItem>
          <ListItem>"The Enchanted Forest" by Rebecca Grey</ListItem>
          <ListItem>"Secrets of the Night" by Olivia White</ListItem>
        </List>
      </div>
    </Paper>
  );
}
