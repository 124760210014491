import { Box, Typography } from '@mui/material';

/**
 * ErrorComponent displays an error message.
 * It uses the Material-UI Box and Typography components to show the error.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.error - Error object containing error details.
 * @param {string} props.error.errorCode - Error code to display.
 * @returns {React.ReactElement} The ErrorComponent element.
 */
export const ErrorComponent = ({ error }) => {
  return (
    <Box sx={{ bgcolor: 'background.default', p: 2 }}>
      <Typography variant="h6">An Error Occurred: {error.errorCode}</Typography>
    </Box>
  );
};
