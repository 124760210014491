// src/views/public/AuthorProfiles.js

import { Link } from "react-router-dom"; // Import Link from react-router-dom

export function AuthorProfiles() {
  return (
      <div className="public-layout__container">
        <h2 className="public-layout__heading">
          Get to know the talented authors behind our captivating books:
        </h2>
        <ul className="public-layout__list">
          <li>
            <Link to="/authors/laura-green">Laura Green</Link>
          </li>
          <li>
            <Link to="/authors/henry-masters">Henry Masters</Link>
          </li>
          <li>
            <Link to="/authors/samantha-waters">Samantha Waters</Link>
          </li>
          <li>
            <Link to="/authors/alex-thompson">Alex Thompson</Link>
          </li>
          <li>
            <Link to="/authors/rebecca-grey">Rebecca Grey</Link>
          </li>
          <li>
            <Link to="/authors/david-carter">David Carter</Link>
          </li>
          <li>
            <Link to="/authors/ella-johnson">Ella Johnson</Link>
          </li>
          <li>
            <Link to="/authors/michael-williams">Michael Williams</Link>
          </li>
          <li>
            <Link to="/authors/sophia-lewis">Sophia Lewis</Link>
          </li>
          <li>
            <Link to="/authors/john-mitchell">John Mitchell</Link>
          </li>
          <li>
            <Link to="/authors/olivia-white">Olivia White</Link>
          </li>
        </ul>
      </div>
  );
}