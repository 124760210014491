import { loginRequest } from '@/config/authConfig';
import { instance } from '@/config/axiosConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function useTokenManager() {
  const { instance: msalInstance, accounts } = useMsal();
  const [cachedToken, setCachedToken] = useState(null);

  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    const account = accounts[0];

    if (account) {
      const tokenRequest = { scopes: loginRequest.scopes, account };

      msalInstance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          setCachedToken(response.accessToken);
          console.log('Token acquired: ', response.accessToken); // For debugging
        })
        .catch((error) => {
          console.error('Token acquisition failed: ', error); // For debugging
          if (error instanceof InteractionRequiredAuthError) {
            return msalInstance.acquireTokenRedirect(tokenRequest);
          }
        });
    }
  }, [accounts, msalInstance]);

  useEffect(() => {
    if (cachedToken) {
      instance.defaults.headers.common[
        'authorization'
      ] = `Bearer ${cachedToken}`;
      instance.defaults.headers.common['x-routing-key'] = currentPath;
      console.log('Authorization Header set'); // For debugging
    }
  }, [cachedToken, currentPath]);

  return cachedToken;
}

export default useTokenManager;
