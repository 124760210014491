import { createTheme } from '@mui/material/styles';

export const baseTheme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
    },
    background: {
      default: '#f4f5f7',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#555555',
    },
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: '2.5rem',
    },
    h2: {
      fontWeight: 500,
      fontSize: '2rem',
    },
    h3: {
      fontWeight: 500,
      fontSize: '1.75rem',
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.5rem',
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.25rem',
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

// Archive theme with a cyberpunk dark feel
export const archiveTheme = createTheme(baseTheme, {
  palette: {
    primary: {
      main: '#8DF3FF', // Bright cyan for primary color, used in links and highlights
    },
    secondary: {
      main: '#4EB6D2', // Lighter cyan for secondary color, used for hover states
    },
    background: {
      default: '#1E1F23', // Dark grey for background
      paper: '#1a1a1a', // Slightly lighter grey for paper elements
    },
    text: {
      primary: '#8DF3FF', // Cyan for primary text
      secondary: '#4EB6D2', // Lighter cyan for secondary text
    },
    error: {
      main: '#f44336', // Red for errors (can be adjusted if needed)
    },
    warning: {
      main: '#ffc107', // Amber for warnings (can be adjusted if needed)
    },
    info: {
      main: '#2196f3', // Blue for information (can be adjusted if needed)
    },
    success: {
      main: '#4caf50', // Green for success (can be adjusted if needed)
    },
    typography: {
      fontFamily: '"Orbitron", sans-serif',
    },
  },
});

// Public theme with an old-fashioned bookstore feel
export const publicTheme = createTheme(baseTheme, {
  palette: {
    primary: {
      main: '#8B4513', // Saddle Brown, reminiscent of old leather books
      contrastText: '#FFF8DC', // Cornsilk, for readability
    },
    secondary: {
      main: '#D2B48C', // Tan, a soft, warm hue
      contrastText: '#000000', // Black, for strong contrast on secondary elements
    },
    background: {
      default: '#FFF8DC', // Cornsilk, a warm, inviting background
      paper: '#FFFAF0', // Floral White, for paper elements
    },
    text: {
      primary: '#000000', // Black, for strong readability
      secondary: '#6B8E23', // Olive Drab, for subtler text
    },
  },
  typography: {
    fontFamily: '"Times New Roman", Times, serif', // Classic font for a traditional look
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    // ... other typography adjustments
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#FFF8DC', // Consistent background with the theme
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // Style adjustments for buttons
        },
      },
    },
    // ... other component customizations
  },
});
