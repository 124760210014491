import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';

export const OutlinedInputWithButton = ({ onSubmit, defaultText }) => {
  const [inputValue, setInputValue] = useState(defaultText || '');
  const theme = useTheme();

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(inputValue);
    setInputValue(''); // Reset the input field after submission
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        width: '100%',
      }}
    >
      <TextField
        variant="outlined"
        value={inputValue}
        onChange={handleChange}
        multiline
        rows={6} // Set to 6 lines
        fullWidth
        sx={{
          width: '75%', // Set width to 75% of the parent
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme.palette.secondary.main,
            },
            '&:hover fieldset': {
              borderColor: theme.palette.secondary.dark,
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.secondary.light,
            },
          },
        }}
      />
      <Button
        variant="contained"
        onClick={handleSubmit}
        sx={{ width: '15%', mt: 2 }}
      >
        Submit
      </Button>
    </Box>
  );
};
