import { archiveTheme } from '@/assets/styles/Theme';
import { loginRequest } from '@/config/authConfig';
import { ErrorComponent } from '@/ui-components/ErrorComponent';
import { Loading } from '@/ui-components/Loading';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { CssBaseline } from '@mui/material';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const ArchiveLayout = () => {
  return (
    <ThemeProvider theme={archiveTheme}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        <Box sx={{ width: '100%' }}>
          <CssBaseline />
          <Box
            sx={{ bgcolor: 'background.default', width: '100%', padding: 3 }}
          >
            <Outlet />
          </Box>
        </Box>
      </MsalAuthenticationTemplate>{' '}
    </ThemeProvider>
  );
};
