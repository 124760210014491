import { Box, Typography } from '@mui/material';

/**
 * Loading component to indicate authentication progress.
 * It uses Material-UI's Typography component to display a centered loading message.
 *
 * @returns {React.ReactElement} The Loading element.
 */
export const Loading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // full viewport height
        bgcolor: 'background.default',
      }}
    >
      <Typography variant="h3">Authentication in progress...</Typography>
    </Box>
  );
};
