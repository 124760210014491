import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  return (
    <Container>
      <Box textAlign="center" my={4}>
        <Typography variant="h1" gutterBottom>
          You Have Become Lost in Time and Space!
        </Typography>
        <Typography paragraph>
          Our daring digital explorers are on a perilous mission to retrieve the
          lost page.
        </Typography>
        <Typography paragraph>
          Did it vanish into a black hole, go on a cosmic vacation, or get
          abducted by aliens?
        </Typography>
        <Typography paragraph>
          Perhaps we were just too entranced by future-tech to build it.
        </Typography>
        <Typography paragraph>
          While these electronic enigmas continue to baffle us, why not navigate
          back to the <Link to="/homepage">mothership</Link>?
        </Typography>
      </Box>
    </Container>
  );
};
