// src/ui-components/CustomAlert.jsx
import Alert from '@mui/material/Alert';
import React from 'react';

/**
 * Custom alert component to display messages.
 *
 * @param {Object} props - Component props.
 * @param {string} props.message - The message to be displayed.
 * @param {string} props.severity - The severity of the alert ('error' or 'success').
 * @returns {React.ReactElement | null} The Alert component or null if no message.
 */
const CustomAlert = ({ message, severity }) => {
  if (!message) return null;

  return (
    <Alert sx={{ width: '75%' }} severity={severity}>
      {message}
    </Alert>
  );
};

export default CustomAlert;
